<template>
  <div>Menu</div>
</template>

<script>
export default {
  name: 'Menu',
};
</script>

<style>

</style>
